import { render, staticRenderFns } from "./default.vue?vue&type=template&id=93e33f46&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageHeaderNew: require('/var/www/dnipro-m/dnipro-m.cz/app/components/layouts/PageHeaderNew.vue').default,PageFooterNew: require('/var/www/dnipro-m/dnipro-m.cz/app/components/layouts/PageFooterNew.vue').default})

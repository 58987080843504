export const PageFooter = () => import('../../components/layouts/PageFooter.vue' /* webpackChunkName: "components/page-footer" */).then(c => wrapFunctional(c.default || c))
export const PageFooterNew = () => import('../../components/layouts/PageFooterNew.vue' /* webpackChunkName: "components/page-footer-new" */).then(c => wrapFunctional(c.default || c))
export const PageHeader = () => import('../../components/layouts/PageHeader.vue' /* webpackChunkName: "components/page-header" */).then(c => wrapFunctional(c.default || c))
export const PageHeaderNew = () => import('../../components/layouts/PageHeaderNew.vue' /* webpackChunkName: "components/page-header-new" */).then(c => wrapFunctional(c.default || c))
export const BePartner = () => import('../../components/blocks/BePartner.vue' /* webpackChunkName: "components/be-partner" */).then(c => wrapFunctional(c.default || c))
export const BenefitsSection = () => import('../../components/blocks/BenefitsSection.vue' /* webpackChunkName: "components/benefits-section" */).then(c => wrapFunctional(c.default || c))
export const CatalogsSection = () => import('../../components/blocks/CatalogsSection.vue' /* webpackChunkName: "components/catalogs-section" */).then(c => wrapFunctional(c.default || c))
export const CertificatesSection = () => import('../../components/blocks/CertificatesSection.vue' /* webpackChunkName: "components/certificates-section" */).then(c => wrapFunctional(c.default || c))
export const ChartSection = () => import('../../components/blocks/ChartSection.vue' /* webpackChunkName: "components/chart-section" */).then(c => wrapFunctional(c.default || c))
export const FinanceSection = () => import('../../components/blocks/FinanceSection.vue' /* webpackChunkName: "components/finance-section" */).then(c => wrapFunctional(c.default || c))
export const MedalsSection = () => import('../../components/blocks/MedalsSection.vue' /* webpackChunkName: "components/medals-section" */).then(c => wrapFunctional(c.default || c))
export const NominationSection = () => import('../../components/blocks/NominationSection.vue' /* webpackChunkName: "components/nomination-section" */).then(c => wrapFunctional(c.default || c))
export const OurTools = () => import('../../components/blocks/OurTools.vue' /* webpackChunkName: "components/our-tools" */).then(c => wrapFunctional(c.default || c))
export const PartnersSection = () => import('../../components/blocks/PartnersSection.vue' /* webpackChunkName: "components/partners-section" */).then(c => wrapFunctional(c.default || c))
export const PrinciplesOfCompany = () => import('../../components/blocks/PrinciplesOfCompany.vue' /* webpackChunkName: "components/principles-of-company" */).then(c => wrapFunctional(c.default || c))
export const SectionHeading = () => import('../../components/blocks/SectionHeading.vue' /* webpackChunkName: "components/section-heading" */).then(c => wrapFunctional(c.default || c))
export const SlidesBlock = () => import('../../components/blocks/SlidesBlock.vue' /* webpackChunkName: "components/slides-block" */).then(c => wrapFunctional(c.default || c))
export const StatsSection = () => import('../../components/blocks/StatsSection.vue' /* webpackChunkName: "components/stats-section" */).then(c => wrapFunctional(c.default || c))
export const StepsSection = () => import('../../components/blocks/StepsSection.vue' /* webpackChunkName: "components/steps-section" */).then(c => wrapFunctional(c.default || c))
export const ToolsExport = () => import('../../components/blocks/ToolsExport.vue' /* webpackChunkName: "components/tools-export" */).then(c => wrapFunctional(c.default || c))
export const ToolsSection = () => import('../../components/blocks/ToolsSection.vue' /* webpackChunkName: "components/tools-section" */).then(c => wrapFunctional(c.default || c))
export const WeProvide = () => import('../../components/blocks/WeProvide.vue' /* webpackChunkName: "components/we-provide" */).then(c => wrapFunctional(c.default || c))
export const ContentModal = () => import('../../components/helpers/ContentModal.vue' /* webpackChunkName: "components/content-modal" */).then(c => wrapFunctional(c.default || c))
export const LangSwitcher = () => import('../../components/helpers/LangSwitcher.vue' /* webpackChunkName: "components/lang-switcher" */).then(c => wrapFunctional(c.default || c))
export const MainVideo = () => import('../../components/helpers/MainVideo.vue' /* webpackChunkName: "components/main-video" */).then(c => wrapFunctional(c.default || c))
export const Typewriter = () => import('../../components/helpers/Typewriter.vue' /* webpackChunkName: "components/typewriter" */).then(c => wrapFunctional(c.default || c))
export const VideoModal = () => import('../../components/helpers/VideoModal.vue' /* webpackChunkName: "components/video-modal" */).then(c => wrapFunctional(c.default || c))
export const ChartsChartEn = () => import('../../components/charts/ChartEn.vue' /* webpackChunkName: "components/charts-chart-en" */).then(c => wrapFunctional(c.default || c))
export const ChartsChartRo = () => import('../../components/charts/ChartRo.vue' /* webpackChunkName: "components/charts-chart-ro" */).then(c => wrapFunctional(c.default || c))
export const ChartsChartRu = () => import('../../components/charts/ChartRu.vue' /* webpackChunkName: "components/charts-chart-ru" */).then(c => wrapFunctional(c.default || c))
export const ChartsChartUk = () => import('../../components/charts/ChartUk.vue' /* webpackChunkName: "components/charts-chart-uk" */).then(c => wrapFunctional(c.default || c))
export const InputsBaseInput = () => import('../../components/inputs/BaseInput.vue' /* webpackChunkName: "components/inputs-base-input" */).then(c => wrapFunctional(c.default || c))
export const InputsBaseSelect = () => import('../../components/inputs/BaseSelect.vue' /* webpackChunkName: "components/inputs-base-select" */).then(c => wrapFunctional(c.default || c))
export const InputsLangSelect = () => import('../../components/inputs/LangSelect.vue' /* webpackChunkName: "components/inputs-lang-select" */).then(c => wrapFunctional(c.default || c))
export const InputsPhoneInput = () => import('../../components/inputs/PhoneInput.vue' /* webpackChunkName: "components/inputs-phone-input" */).then(c => wrapFunctional(c.default || c))
export const MailForm = () => import('../../components/mail-form/MailForm.vue' /* webpackChunkName: "components/mail-form" */).then(c => wrapFunctional(c.default || c))
export const Version2023AboutProduct = () => import('../../components/version-2023/AboutProduct.vue' /* webpackChunkName: "components/version2023-about-product" */).then(c => wrapFunctional(c.default || c))
export const Version2023AboutUs = () => import('../../components/version-2023/AboutUs.vue' /* webpackChunkName: "components/version2023-about-us" */).then(c => wrapFunctional(c.default || c))
export const Version2023Advantages = () => import('../../components/version-2023/Advantages.vue' /* webpackChunkName: "components/version2023-advantages" */).then(c => wrapFunctional(c.default || c))
export const Version2023Banner = () => import('../../components/version-2023/Banner.vue' /* webpackChunkName: "components/version2023-banner" */).then(c => wrapFunctional(c.default || c))
export const Version2023Christmas = () => import('../../components/version-2023/Christmas.vue' /* webpackChunkName: "components/version2023-christmas" */).then(c => wrapFunctional(c.default || c))
export const Version2023FirstPurchase = () => import('../../components/version-2023/FirstPurchase.vue' /* webpackChunkName: "components/version2023-first-purchase" */).then(c => wrapFunctional(c.default || c))
export const Version2023FooterForm = () => import('../../components/version-2023/FooterForm.vue' /* webpackChunkName: "components/version2023-footer-form" */).then(c => wrapFunctional(c.default || c))
export const Version2023GardenToolFestival = () => import('../../components/version-2023/GardenToolFestival.vue' /* webpackChunkName: "components/version2023-garden-tool-festival" */).then(c => wrapFunctional(c.default || c))
export const Version2023GdprPopup = () => import('../../components/version-2023/GdprPopup.vue' /* webpackChunkName: "components/version2023-gdpr-popup" */).then(c => wrapFunctional(c.default || c))
export const Version2023GoogleMap = () => import('../../components/version-2023/GoogleMap.vue' /* webpackChunkName: "components/version2023-google-map" */).then(c => wrapFunctional(c.default || c))
export const Version2023GuestPrize = () => import('../../components/version-2023/GuestPrize.vue' /* webpackChunkName: "components/version2023-guest-prize" */).then(c => wrapFunctional(c.default || c))
export const Version2023InWorld = () => import('../../components/version-2023/InWorld.vue' /* webpackChunkName: "components/version2023-in-world" */).then(c => wrapFunctional(c.default || c))
export const Version2023LangSwitcher = () => import('../../components/version-2023/LangSwitcher.vue' /* webpackChunkName: "components/version2023-lang-switcher" */).then(c => wrapFunctional(c.default || c))
export const Version2023OpeningShops = () => import('../../components/version-2023/OpeningShops.vue' /* webpackChunkName: "components/version2023-opening-shops" */).then(c => wrapFunctional(c.default || c))
export const Version2023OurFlagmans = () => import('../../components/version-2023/OurFlagmans.vue' /* webpackChunkName: "components/version2023-our-flagmans" */).then(c => wrapFunctional(c.default || c))
export const Version2023OurPrinciples = () => import('../../components/version-2023/OurPrinciples.vue' /* webpackChunkName: "components/version2023-our-principles" */).then(c => wrapFunctional(c.default || c))
export const Version2023PhotoTicker = () => import('../../components/version-2023/PhotoTicker.vue' /* webpackChunkName: "components/version2023-photo-ticker" */).then(c => wrapFunctional(c.default || c))
export const Version2023PrizeBanner = () => import('../../components/version-2023/PrizeBanner.vue' /* webpackChunkName: "components/version2023-prize-banner" */).then(c => wrapFunctional(c.default || c))
export const Version2023PrizePopup = () => import('../../components/version-2023/PrizePopup.vue' /* webpackChunkName: "components/version2023-prize-popup" */).then(c => wrapFunctional(c.default || c))
export const Version2023ProfessionalTools = () => import('../../components/version-2023/ProfessionalTools.vue' /* webpackChunkName: "components/version2023-professional-tools" */).then(c => wrapFunctional(c.default || c))
export const Version2023Social = () => import('../../components/version-2023/Social.vue' /* webpackChunkName: "components/version2023-social" */).then(c => wrapFunctional(c.default || c))
export const Version2023TestDrive = () => import('../../components/version-2023/TestDrive.vue' /* webpackChunkName: "components/version2023-test-drive" */).then(c => wrapFunctional(c.default || c))
export const Version2023TestDriveModal = () => import('../../components/version-2023/TestDriveModal.vue' /* webpackChunkName: "components/version2023-test-drive-modal" */).then(c => wrapFunctional(c.default || c))
export const Version2023TradeIn = () => import('../../components/version-2023/TradeIn.vue' /* webpackChunkName: "components/version2023-trade-in" */).then(c => wrapFunctional(c.default || c))
export const Version2023VideoBg = () => import('../../components/version-2023/VideoBg.vue' /* webpackChunkName: "components/version2023-video-bg" */).then(c => wrapFunctional(c.default || c))
export const Version2023WorkTimeDropdown = () => import('../../components/version-2023/WorkTimeDropdown.vue' /* webpackChunkName: "components/version2023-work-time-dropdown" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}

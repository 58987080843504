
import LangSelect from '~/components/inputs/LangSelect'
export default {
  components: { LangSelect },
  computed: {
    availableLocales() {
      return this.$i18n.locales.filter((i) => i.code !== this.$i18n.locale)
    },
    currentLocale: {
      get() {
        return this.$i18n.locales.find((i) => i.code === this.$i18n.locale)
      },
      set({ code }) {
        this.$i18n.setLocale(code)
      },
    },
  },
}


export default {
  name: 'LangSelect',
  props: {
    options: {
      type: Array,
      required: true,
    },
    default: {
      type: [String, Object, Number, Boolean],
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      selected: this.default
        ? this.default
        : this.options.length > 0
        ? this.options[0]
        : null,
      open: false,
    }
  },
  mounted() {
    this.$emit('input', this.selected)
  },
  methods: {
    clickHandler(option) {
      this.selected = option
      this.open = false
      this.$emit('input', option)
    },
  },
}


import Social from '~/components/version-2023/Social'
import FooterForm from '~/components/version-2023/FooterForm'

export default {
  name: 'PageFooterNew',
  components: {
    Social,
    FooterForm,
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
    }
  },
}

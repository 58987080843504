
import Social from '~/components/version-2023/Social'
import LangSwitcher from '~/components/version-2023/LangSwitcher'

export default {
  name: 'PageHeader',
  components: {
    Social,
    LangSwitcher,
  },
  props: {
    filled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActiveHeader: false,
      isVisibleHeadingButton: false,
      open: false,
    }
  },
  beforeMount() {
    if (!this.filled) {
      window.addEventListener('scroll', this.scrollHandler)
    } else {
      this.isActiveHeader = true
      this.isVisibleHeadingButton = true
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollHandler)
  },
  methods: {
    openESModal() {
      window.eS('showForm', { formVariantId: 'f8632v8632' })
    },
    scrollToBlock(selector) {
      const header = document.querySelector('.header')
      if (header) {
        this.$gsap.to(window, {
          duration: 1,
          scrollTo: {
            y: selector,
            offsetY: header.offsetHeight,
          },
        })
        this.open = false
      }
    },
    scrollHandler() {
      const headerHeight = this.$refs.header.offsetHeight
      const headingSection = document.querySelector('.first-section')
      const isHeadingSectionVisible =
        headingSection?.getBoundingClientRect()?.bottom > headerHeight
      this.isActiveHeader = !(window.scrollY < 50)
      this.isVisibleHeadingButton = !isHeadingSectionVisible
    },
  },
}

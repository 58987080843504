
export default {
  name: 'PhoneInput',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCreated: false,
      dialCode: '',
      isDropdownOpen: false,
    }
  },
  computed: {
    inputOptions() {
      return {
        autocomplete: 'on',
        autofocus: false,
        'aria-describedby': '',
        id: '',
        maxlength: 25,
        name: 'phone',
        placeholder: this.$t('footer_form.phonePlaceholder'),
        readonly: false,
        required: false,
        tabindex: 0,
        type: 'tel',
        styleClasses: '',
      }
    },
    localeIso2() {
      let iso2
      if (this.$i18n.locale === 'uk') {
        iso2 = 'UA'
      } else if (this.$i18n.locale === 'en') {
        iso2 = 'GB'
      } else if (this.$i18n.locale === 'pl') {
        iso2 = 'PL'
      }
      return iso2
    },
    innerValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    countryChangedHandler({ dialCode }) {
      if (this.isDropdownOpen) {
        setTimeout(() => {
          this.dialCode = `+${dialCode}`
          this.$emit('input', `+${dialCode}`)
          if (this.isCreated) {
            try {
              this.$refs.telInput.$el.querySelector('input[type="tel"]').focus()
            } catch (e) {}
          }
          this.isCreated = true
        }, 0)
      }
    },
    validateHandler({ valid }) {
      this.$emit('validate', valid)
    },
  },
}

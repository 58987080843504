
export default {
  layout: 'clear',
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  head() {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true })
    return {
      meta: [...i18nHead.meta],
      htmlAttrs: {
        ...i18nHead.htmlAttrs,
      },
      link: [...i18nHead.link],
    }
  },
}

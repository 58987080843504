import { render, staticRenderFns } from "./clear.vue?vue&type=template&id=27d87289&scoped=true&"
import script from "./clear.vue?vue&type=script&lang=js&"
export * from "./clear.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27d87289",
  null
  
)

export default component.exports

import { required, email, minLength, maxLength } from 'vuelidate/lib/validators'
import BaseInput from '~/components/inputs/BaseInput'
import PhoneInput from '~/components/inputs/PhoneInput'

const formBaseState = {
  name: '',
  email: '',
  message: '',
  phone: '',
}
export default {
  name: 'FooterForm',
  components: { BaseInput, PhoneInput },
  data() {
    return {
      ...formBaseState,
      isFormSubmiting: false,
      isPhoneValid: false,
      isPhoneValidated: false,
      isAgree: false,
    }
  },
  validations: {
    name: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(60),
    },
    email: {
      required,
      email,
    },
  },
  methods: {
    submitForm() {
      this.$v.$touch()
      this.isPhoneValidated = true
      if (
        this.$v.$invalid ||
        this.isFormSubmiting ||
        this.isPhoneValid !== true
      ) {
        return
      }

      this.isFormSubmiting = true
      const { name, email, message } = this
      const phone = this.phone.replace(/\s+/g, '')
      this.$axios
        .$post(
          '/api/tickets.json',
          {
            ticket: {
              subject: 'Contact request cz.dnipro-m.eu',
              tags: ['форма_сайт_cz'],
              requester: {
                name,
                phone,
                email,
              },
              comment: {
                body: `
                  name: ${name},
                  phone: ${phone},
                  email: ${email},
                  message: ${message}
                `,
              },
            },
          },
          {
            headers: {
              Authorization: `Basic ${btoa(
                `${this.$config.zendeskLogin}:${this.$config.zendeskPassword}`
              )}`,
              'Content-Type': 'application/json',
            },
          }
        )
        .then(() => {
          this.clearForm()
          this.$v.$reset()
          this.isPhoneValidated = false
          this.$notify({
            type: 'success',
            title: this.$t('footer_form.successMessage'),
          })
        })
        .catch(({ response }) => {
          this.$notify({
            type: 'error',
            title: this.$t('footer_form.errorMessage'),
          })
          const serverErrors = response.data?.errors || {}
          if (Object.keys(serverErrors).length) {
            this.$v.$reset()
            for (const key in serverErrors) {
              this.$notify({
                type: 'error',
                title: `${serverErrors[key]} (${key})`,
              })
            }
          }
        })
        .finally(() => {
          this.isFormSubmiting = false
        })
      // this.$mail
      //   .send({
      //     from: this.$config.mailFrom,
      //     subject: 'Contact request',
      //     text: `
      //       Name: ${name},
      //       Email: ${email},
      //       Message: ${message},
      //       Phone: ${phone},
      //     `,
      //   })
      //   .then(() => {
      //     this.clearForm()
      //     this.$v.$reset()
      //     this.isPhoneValidated = false
      //     this.$notify({
      //       type: 'success',
      //       title: this.$t('footer_form.successMessage'),
      //     })
      //   })
      //   .catch(({ response }) => {
      //     this.$notify({
      //       type: 'error',
      //       title: this.$t('footer_form.errorMessage'),
      //     })
      //     const serverErrors = response.data?.errors || {}
      //     if (Object.keys(serverErrors).length) {
      //       this.$v.$reset()
      //       for (const key in serverErrors) {
      //         this.$notify({
      //           type: 'error',
      //           title: `${serverErrors[key]} (${key})`,
      //         })
      //       }
      //     }
      //   })
      //   .finally(() => {
      //     this.isFormSubmiting = false
      //   })
    },
    clearForm() {
      for (const key in formBaseState) {
        this[key] = formBaseState[key]
      }
    },
    validatePhone(val) {
      this.isPhoneValid = val
    },
  },
}
